import React from 'react';
import styled from '@emotion/styled';
import MuiGrid, { GridProps } from '@mui/material/Grid';
import { withTheme } from '@emotion/react';
import { Theme, ThemeProps } from '@theme/base';
import { breakpoints } from '@styles/breakpoints';
import { TestId } from '@components/test-ids';

export interface GridCarouselProps extends GridProps {
  theme?: Theme;
  t?: (label: any) => any;
}

const Container = styled.div({});

const GridContainer = styled(MuiGrid)(({ theme }: ThemeProps) => ({
  ['&.MuiGrid-container']: {
    paddingBottom: 6
  },
  [`@media (max-width: ${breakpoints.small}px)`]: {
    ['&.MuiGrid-container']: {
      flexDirection: 'row',
      flexWrap: 'nowrap',
      marginRight: -theme.custom.spacing.large,
      overflow: 'scroll',
      paddingLeft: theme.custom.spacing.medium,
      paddingRight: theme.custom.spacing.large,
      scrollbarWidth: 'none',
      scrollSnapType: 'x mandatory',
      width: 'auto',
      ['&::-webkit-scrollbar']: {
        display: 'none'
      },

      ['.MuiGrid-item']: {
        flex: '1 0 auto',
        paddingLeft: theme.custom.spacing.medium,
        scrollSnapAlign: 'center',
        width: '90vw'
      }
    }
  }
}));

export const Grid = ({ children, ...props }: GridCarouselProps) => {
  const { t, ...gridProps } = props;
  return (
    <Container data-testid={TestId.gridCarousel.container}>
      <GridContainer {...gridProps}>{children}</GridContainer>
    </Container>
  );
};

export const GridCarousel = withTheme(Grid);
