import React from 'react';
import styled from '@emotion/styled';
import { useAsync } from 'react-use';
import { ContentfulApi } from '@model/contentful/service';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import { PromoBannerData } from '@model/promo-banner';
import { Entry } from '@model/contentful';
import { ModalType, showModal } from '@state/modal/modalOperations';
import { setPromoModal } from '@state/app';
import { useDispatch } from 'react-redux';
import { TestId } from '@components/test-ids';
import { isDesktop, isMobile, isTablet, mq } from '@styles/breakpoints';

const mobileStyles = {
  position: 'absolute',
  width: '90%',
  marginBottom: 0,
  top: '12%'
};

const ImageContainer: any = styled.div<any>(({ mobileStyles }: any) => ({
  width: '100%',
  marginBottom: '80px',
  [mq.small]: {
    marginBottom: '50px'
  },

  ...mobileStyles,

  ['&.isTablet']: {
    top: '20%'
  }
}));

const PromoImage = styled.img({
  cursor: 'pointer',
  height: '100%',
  width: '100%'
});

export const SRC_URL = {
  desktop: '/assets/images/promo/super-summer-sale-desktop.png',
  mobile: '/assets/images/promo/super-summer-sale-mobile.png'
};

const HOMEPAGE_BANNER_BANNER_ID: string = '53N3g83CNpdYROsrncfmTD';

const HOMEPAGE_BANNER_ACTIVE = false;

export const HomepageBannerContainer = () => {
  const dispatch = useDispatch();
  const isDesktopView = isDesktop();

  const data: AsyncState<Entry<PromoBannerData> | null> = useAsync(async () => {
    if (!HOMEPAGE_BANNER_ACTIVE) return null;
    const modalConfig = await new ContentfulApi().getCMSEntry(HOMEPAGE_BANNER_BANNER_ID, {});
    const modal = modalConfig.fields?.modal.fields;
    if (modal) {
      dispatch(setPromoModal(modal));
    }
    return modalConfig;
  }, []);

  if (!data || data.error || !data.value?.fields) {
    return null;
  }

  if (data.loading) return <div style={{ height: 45 }} />;
  const {
    modal: { fields: PromoBannerData }
  } = data?.value.fields;
  const handleOnClick = () => dispatch(showModal(ModalType.PROMO_BANNER_MODAL, PromoBannerData));

  return (
    <ImageContainer
      data-testid={TestId.promoBanner.main}
      mobileStyles={!isDesktopView ? mobileStyles : ''}
      className={isTablet() && !isMobile() ? 'isTablet' : ''}
    >
      <PromoImage src={isDesktopView ? SRC_URL.desktop : SRC_URL.mobile} onClick={handleOnClick} />
    </ImageContainer>
  );
};
