import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import dynamic from 'next/dynamic';
import { SectionTitleSizes } from '@components/common/section-title';
import { Head } from '@components/common/head';
import { CMSComponents } from '@components/cms';
import { getHeroData } from '@components/util';
import { CmsPages, TripTypes } from '@model/contentful';
import { withTheme } from '@emotion/react';
import { Theme } from '@theme/base';
import { SectionSeparator } from '@styles/layout';
import { Footer } from '@components/footer';
import { HomeHero } from '@components/common/hero/home/HomeHero';
import { Error500 } from '@components/common/hero/error/Error500';
import { RecentlyViewedCarouselContainer } from '@components/recently-viewed/RecentlyViewedCarouselContainer';
import { withGlobalProvider } from 'providers/globalProvider';
import { storeWrapper } from '@state/store';
import { GetStaticProps } from 'next';
import { initializeApp } from '@state/initializeApp';
import { ContentfulApi } from '@model/contentful/service';
import { GenericPageConfig } from '@model/contentful/common/generic-page-config';
import { getHomeMeta } from '@components/meta/get-home-meta';
import { getHTML } from '@util/common';
import { useI18NextContext } from '@components/hooks';
import { LazyLoadComponent } from '@components/hoc';
import { TabSearchVariant } from '@components/search';
import { ImageResponse } from '@model/iceberg';
import { Revalidate } from '@model/config/revalidate';
import { AppVariant } from '@model/config/brands';
import { ERROR_CODES } from '@model/common/error-codes';

export interface HomePageProps {
  theme: Theme;
  cmsConfig: GenericPageConfig;
  hero: ImageResponse;
}

const SearchTabsBar = dynamic(() => import('../src/components/search/SearchTabsBar'));

const HomePage = (props: HomePageProps) => {
  const { cmsConfig, hero } = props;
  const t = useI18NextContext();
  const router = useRouter();
  useEffect(() => {
    router.prefetch('/search');
  }, []);
  const isWhiteLabel = AppVariant.isWhitelabel();

  if (!cmsConfig) return <Error500 code={ERROR_CODES.NO_CMS_CONFIG} />;

  return (
    <>
      <Head metaTitle={t('meta__title--homepage')} metaDescription={cmsConfig.metaDescription} />
      {!isWhiteLabel && (
        <Script type="application/ld+json" dangerouslySetInnerHTML={getHTML(JSON.stringify(getHomeMeta()))} />
      )}
      <HomeHero
        image={hero}
        renderSearch={isWhiteLabel ? null : <SearchTabsBar variant={TabSearchVariant.HOMEPAGE} />}
        isWhiteLabel={isWhiteLabel}
      />
      {isWhiteLabel && <SectionSeparator />}
      {!isWhiteLabel && (
        <>
          <RecentlyViewedCarouselContainer />
          <SectionSeparator />
        </>
      )}
      <LazyLoadComponent>
        <CMSComponents components={cmsConfig.components} titleSize={SectionTitleSizes.LARGE} />
        <SectionSeparator />
        <Footer />
      </LazyLoadComponent>
    </>
  );
};

export const getStaticProps: GetStaticProps = storeWrapper.getStaticProps((store) => async () => {
  const props = await initializeApp(store, async () => {
    const [cmsConfig, hero] = await Promise.all([
      new ContentfulApi().getPageConfig({ pageId: CmsPages.HOME_PAGE }),
      getHeroData()
    ]);

    return {
      cmsConfig,
      hero,
      promoBannerTripType: TripTypes.ALL
    };
  });
  return {
    ...props,
    revalidate: Revalidate.HALF_HOUR
  };
});

export default withGlobalProvider(withTheme(HomePage));
