import { mq } from '@styles/breakpoints';
import React from 'react';
import styled from '@emotion/styled';

const HeaderGradientStyled = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: 100,
  backgroundImage: 'url(/assets/images/header_gradient.png)',
  backgroundSize: '1px 100%',
  [mq.small]: {
    height: 273,
    backgroundSize: '1px 273px'
  },
  [mq.x2]: {
    backgroundImage: 'url(/assets/images/header_gradient@2x.png)'
  },
  [mq.x3]: {
    backgroundImage: 'url(/assets/images/header_gradient@3x.png)'
  }
});

export const HeaderGradient = () => <HeaderGradientStyled />;
