import React from 'react';
import { BaseHero, Bottom, ImageContainer, Main } from '../base/BaseHero';
import { HeaderGradient } from '@components/common/header-gradient';
import { TestId } from '@components/test-ids';
import { NavBarContainer, NavBarVariants } from '@components/navigation';
import { ImageResponse } from '@model/iceberg';
import { mq } from '@styles/breakpoints';
import styled from '@emotion/styled';
import { TripTypes } from '@model/contentful';
import { BottomContainer } from '../bottom/BottomContainer';
import { ThemeProps } from '@theme/base';
import { HomepageBannerContainer } from '@components/common/banner/homepage/HomepageBannerContainer';

export interface HomeHeroProps {
  image: ImageResponse;
  renderSearch: JSX.Element | null;
  isWhiteLabel: boolean;
}

const LandingContainer = styled.div(({ theme }: ThemeProps) => ({
  padding: 0,
  width: '100%',
  maxWidth: theme.custom.contentSizes.maxContentWidth,
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  margin: '0 auto',
  height: '100%',
  [mq.small]: {
    padding: theme.custom.spacing.medium
  },
  [mq.medium]: {
    padding: `0 ${theme.custom.spacing.medium}`
  }
}));

const MainContainer = styled.div({
  width: '100%',
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',

  [mq.small]: {
    justifyContent: 'center'
  }
});

const BottomWrapper = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'flex-start',
  justifyContent: 'center',
  marginTop: -10,
  position: 'relative',
  [mq.small]: {
    marginTop: -theme.custom.spacing.medium
  },

  [mq.small]: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 0
  },

  ['.badgeContainer']: {
    display: 'none',

    [mq.medium]: {
      display: 'block'
    }
  }
}));

interface StyledBaseHeroProps extends ThemeProps {
  isWhiteLabel: boolean;
}
const StyledBaseHero: any = styled(BaseHero)(({ theme, isWhiteLabel }: StyledBaseHeroProps) => ({
  [mq.small]: {
    height: isWhiteLabel ? '50vh' : '100vh'
  },
  [ImageContainer as any]: {
    paddingBottom: 'min(30vh, 60%)',
    position: 'absolute',
    [mq.small]: {
      height: isWhiteLabel ? '50vh' : '100vh',
      paddingBottom: isWhiteLabel ? '50vh' : '100vh'
    }
  },
  [Main as any]: {
    marginTop: `calc(30vh - ${theme.custom.spacing.xxLarge + theme.custom.spacing.medium * 2 + 2}px)`,
    [mq.small]: {
      marginTop: 0
    }
  },
  [Bottom as any]: {
    display: 'none',
    [mq.small]: {
      display: 'flex'
    }
  }
}));

export const HomeHero = ({ image, renderSearch, isWhiteLabel }: HomeHeroProps) => {
  return (
    <StyledBaseHero
      testId={TestId.homeHero}
      image={image}
      loading={'eager'}
      isWhiteLabel={isWhiteLabel}
      renderTop={
        <>
          <NavBarContainer variant={NavBarVariants.DARK} />
          <HeaderGradient data-testid={TestId.heroHeaderGradient} />
        </>
      }
      renderMain={
        <LandingContainer>
          <MainContainer>
            {renderSearch && <HomepageBannerContainer />}
            {renderSearch}
          </MainContainer>
        </LandingContainer>
      }
      renderBottom={
        <BottomWrapper>
          {image?.destination && (
            <BottomContainer destination={image.destination} tripType={TripTypes.HOLIDAYS} chevron />
          )}
        </BottomWrapper>
      }
    />
  );
};
